@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:40px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:28px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:28px;
	font-family: 'Poppins', sans-serif;
}
h3 {
	font-size:20px;
	font-weight:500;
	line-height:inherit;
	font-family: 'Poppins', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Poppins', sans-serif;
}
h6 {
	font-size:20px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Poppins', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 16px;
	color: #fff;
	font-family: 'Poppins', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#333;
	box-shadow:0 0 0 1px #e5e5e5;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 150px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #000;
	font-family: 'Poppins', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #000;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #000;
	border-width: 6px 5px 0;
    opacity: 1;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #000;
	font-size: 20px;
	font-family: 'Poppins', sans-serif;
}
.links > li > a:hover {
	background-color: #003DA5;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #003DA5;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #003DA5;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 16px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 16px;
}
#nav .nav-panel--dropdown a:hover {
	color: #003DA5;
}
.mobnav-trigger.active {
	background-color: #003DA5;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #003DA5;
}
#nav .nav-item--home .fa-home {
	color: #003DA5 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #003DA5;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #003DA5;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #003DA5;
	border-right-color: #003DA5;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #003DA5 !important;
	padding: 0;
	border-radius: 10px;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
    color:#fff;
}
#mini-cart .actions button.button span span:hover {
	background-color: #fff !important;
    color:#003DA5 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 16px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 16px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 40px;
	color: #000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #003DA5;
	border-radius: 10px;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #003da5;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #fff !important; 
    color: #003DA5 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #000;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #fff;
	color: #000; display: none;
}


/************************** MICS **************************/
/*List elements*/
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul{
     list-style:disc;
     margin-left:25px;
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}

/*Inline search results*/

 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:160%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:768px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
@media only screen and (max-width:767px) {
    /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#000;
         padding:10px;
         font-style:normal;
    }
}
.inline-search-results-quickorder .highlight{
     color:#003DA5 !important
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
/*Change the text 'Switch Customer' to a FA icon for small width devices.*/
@media only screen and (max-width:1199px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:22px;
         text-indent:0;
         float:left;
    }
}
/*Change the account menu icon for mobile/tablet*/
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#000;
}
.top-header-container .header-top-container {
     background-color:#003DA5 ;
     box-shadow:none;
}
.header-top-container .module-header-multi, .header-top-container .module-header-multi .item-welcome-msg {
     margin-left:0;
}
.header-top-container .module-header-multi {
     margin-right:0;
     width:100%;
}
/*Align the text on the top header*/
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     line-height:2em;
}
.top-header-container .module-header-multi p, .header-txt {
     margin-bottom:0 !important;
     line-height:2em;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .links>li>a, .top-header-container a, .top-header-container span{
     color:#fff;
     font-size:18px;
     font-weight:500;
}
.desktop-top-header {
     padding-top:20px;
     padding-bottom:20px;
     border-bottom:1px solid #D9D9D9;
     box-shadow:0 2px 3px #d9d9d9;
}
.desktop-top-header .grid-container {
     display:flex;
     justify-content: space-between;
}
.desktop-top-header .logo-column, .desktop-top-header .cart-column {
     margin:auto 0;
}
@media only screen and (min-width:960px) {
.desktop-top-header .logo-column {
    width: 10%;
    margin-left: -2%;
}
}
.desktop-top-header .cart-column {
    width:auto;
}
.desktop-top-header .category-column {
     margin:auto 0;
}
.desktop-top-header .search-column {
    margin:auto 1%;
}
.category-column .module-category-menu .nav-item.nav-item--home {
     display:none;
}

.category-column ul#nav {
     display:flex;
}
 .category-column ul#nav > li.level0 {
     margin:0 auto;
}
@media only screen and (max-width:1299px) {
    .nav-regular .nav-item.level0>a {
        padding-left: 0;
    }
}
.cart-column .fa.fa-shopping-cart {
     font-size:22px !important;
}
.search-column .form-search .input-text {
     border-color:#000;
}
@media only screen and (max-width:1599px) {
     .nav-regular li.level0>a>span {
         font-size:18px;
    }
}
@media only screen and (max-width:1439px) {
     .nav-regular li.level0>a>span {
         font-size:16px;
    }
}
@media only screen and (max-width:1199px) {
     .nav-regular li.level0>a>span {
         font-size:12px;
    }
}
@media only screen and (max-width:959px) {
     #header .fa:not(.fa-search) {
         font-size:22px !important;
    }
     .logo-column {
         width:20%;
    }
     .logo-column img {
         width:100px;
    }
     .search-column {
         width:30%;
    }
     .cart-column {
         width:20%;
    }
     .desktop-top-header, .desktop-top-header .search-column, .desktop-top-header .cart-column, .desktop-top-header .cart-column .module-header-multi {
         overflow:unset;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
    .desktop-top-header {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .top-header-container .module-header-multi span {
        line-height: 3em;
        font-size:16px;
        }
}
@media only screen and (max-width:767px) {
     .mobile-header {
         border-bottom:1px solid #D9D9D9;
         box-shadow:0 2px 3px #d9d9d9;
    }
     .top-header-container {
         margin-bottom:0 !important;
    }
    .top-header-container span {
        font-size:16px;
    }
     .mobile-header .header-top-container {
         background-color:#fff ;
         box-shadow:none;
    }
     .top-header-container .item-switch-customer {
         float:left;
    }
     .mobile-header .logo img {
         max-width:100px;
    }
     .mobile-header .fa {
         color:#000 !important;
    }
     .mobile-header .fa-search {
         font-size:22px !important;
    }
     .mobile-header .module-header-multi {
         display:flex;
    }
     .mobile-header .module-header-multi > div {
         margin:auto;
         width:inherit;
    }
     .mobile-header .fa.fa-shopping-cart {
         top:0;
         left:0;
    }
     .mobile-header .feature-icon-hover .empty {
         display:none;
    }
}
/*Footer*/
.footer-top-container {
     background-color:#000;
}
@media only screen and (min-width:960px) {
     .footer-top-container .footer-top .section {
         display:flex;
    }
     .footer-top-container .footer-top .section > div {
         margin:auto;
    }
     #footer .logo-column {
         margin-left:0;
    }
     #footer .footer-links-column {
         margin-right:0;
    }
    #footer .logo-column img {
         width:90%;
    }
}
.footer-primary.footer.container {
     background-color:#fff;
}
.footer-primary.footer.container .section.clearer {
     padding-top:10px;
     padding-bottom:0;
}
.social-icon-column > div, .footer-links-column > div {
     float:none !important;
}
#footer p.social-icons {
     display:flex;
}
#footer p.social-icons > a {
     margin:0 auto;
}
#footer p.social-icons .fa {
     font-size:20px;
}
#scroll-to-top:hover, .footer .collapsible .opener:hover {
     background-color:#003DA5;
}
.collapsible.mobile-collapsible li a {
     color:#FFF;
     font-weight:300;
     font-size:16px;
}
.collapsible ul.bullet li {
     background:none;
     padding-left:0;
     margin-bottom:5px;
}
.collapsible ul.bullet li:hover a {
     border-bottom:1px solid #fff;
}
.collapsible h6.block-title {
     text-transform:Capitalize;
     color:#FFF;
     font-size:18px;
     font-weight:700;
    letter-spacing: 0px;
}
@media only screen and (min-width:1200px) {
    .collapsible.mobile-collapsible {
        padding-left:10%;
    }
}
@media only screen and (min-width:1600px) {
     .footer-links-column {
         width:77%;
    }
    .collapsible.mobile-collapsible {
        padding-left:20%;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .collapsible h6.block-title {
         font-size:14px;
    }
     .collapsible.mobile-collapsible li a {
         font-size:13px;
    }
     .social-icon-column {
         width:23%;
    }
    #footer .logo-column, .footer-links-column {
        width:98% !important;
    }
    #footer .logo-column {
         text-align:center;
         margin-bottom:20px;
    }
}
@media only screen and (max-width:767px) {
    .header .logo {
        width: inherit !important;
    }
     #footer .logo-column {
         text-align:center;
         margin-bottom:20px;
    }
    #footer .logo-column img {
     width:120px;   
    }
     .footer-links-column > div {
         margin-left:0 !important;
    }
     .footer-primary-container {
         padding:0;
    }
     .footer-primary-container p, .footer-primary-container a {
         font-size:12px;
         text-align:center;
    }
     .social-icon-column {
         margin-top:10px;
    }
}
/*category pages*/
.product-search-container {
     margin-left:0;
}
.product-search-container .button.add-cart-button span {
     color:#003DA5;
     background-color:#fff;
}
.product-search-container .button.add-cart-button:hover span {
     background-color:#003DA5!important;
     color:#fff!important;
}
.toolbar-section {
     margin-bottom:30px;
}
.toolbar-section .module-category-misc{
     display:flex;
}
.toolbar-section .module-category-misc > div {
     margin:auto !important;
}
.toolbar-section .module-category-misc > div:first-child {
     margin-left:0;
}
.toolbar-section .module-category-misc > div:last-child {
     margin-right:0;
}
/*Pagination*/
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pager li a, .pager strong{
     font-size:16px;
}
.pager .pages li a{
     background-color:#003DA5;
     color:#fff;
}
.pager .pages li.current {
     background-color:#fff;
     color:#000;
}
.pager .pages li a:hover {
     background-color:#003DA5;
}
.category-products-grid .item {
     border:none;
     margin-bottom:30px;
}
.product-search-container .block-title, .product-search-container .block-content .accordion-style1 li a {
     border-bottom:none;
}
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
.category-products-grid .item .item-code, .category-products-grid .item span.price{
     font-weight:600;
}
.category-products-grid .item input.qty, .products-list .item input.qty {
     height:24px;
}
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section .module-category-misc{
         display:grid;
         grid-template-columns:auto auto;
         grid-template-rows:40px 40px;
    }
     .products-grid .item button.btn-cart span span {
         padding:0px !important;
    }
    #listing-ul .item .item-code-label {
        display:none;
    }
    .category-products-grid .item .item-code {
        font-size: 12px;
    }
}

/**/
#shopping-cart-table .item-secondary-heading {
     display:none !important;
}
.cart-action-buttons button {
     margin:20px 10px;
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
}
@media only screen and (max-width:959px) {
     .cart-summary-col{
         width:55%;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .cart-action-col {
         overflow:hidden;
    }
     .cart-summary-col{
         width:45%;
    }
}
@media only screen and (min-width:1200px){
     .cart-summary-col {
         width:30%;
    }
     .update-empty-cart {
         width:66%;
    }
}
.quick-orders-page .btn-rm span span{
     background-color:#fff;
     color:#003da5;
     border:1px solid #003da5;
}
.quick-orders-page .btn-rm:hover span span {
     background-color:#003da5 !important;
     color:#fff
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .quick-orders-page .rTable {
         width:99%;
         margin:auto;
    }
}
@media only screen and (max-width:767px) {
     .quick-orders-page .search-box .search-btn-quickorder {
         top:30px;
    }
     .quick-orders-page .rTableCell {
         width:96%;
         display:block;
    }
}

/**/
.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:70px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     float:left;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     margin:0 auto;
     list-style:none;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover {
     background-color:#003da5;
     border-radius:10px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover a{
     color:#fff;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     font-weight:bold;
     padding:0 12px;
     line-height:40px;
}
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
.user-grid-content .page-title {
     overflow:hidden;
}
.account-view .xm-grid-account .grid-container-wrapper {
     overflow-x:hidden;
}
#order-table, #transhistory-table{
     margin-top:20px;
}
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .order-info-box.module-shipment-details-billing, .order-info-box.module-trans-details-shipping, .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:290px;
    }
     /*Align the misc info with billing data*/
     .module-order-details-misc > div {
         padding-left:10px;
    }
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
@media only screen and (max-width:959px) {
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable span{
         font-size:11px !important;
    }
     .module-account-transhistory-content-placeholder {
         margin-top:20px;
    }
}
#openinvc-href span span{
     padding:10px;
}
.page-title.module-trans-details-status h1 span {
     font-size:40px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie {
     padding:10px;
     background-color:#003da5;
     border-radius:10px;
     cursor:pointer;
     color:#fff;
     border:1px solid #003da5;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover {
     background-color:#fff;
     color:#003da5;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
}
.login-section span.company-name {
     display:none;
}
.login-section .grid-container {
     display:flex;
     flex-direction:column;
}
.login-section h1 {
     text-align:center;
}
.login-section .grid-column-wrapper.grid12-12.last {
     width:50%;
     margin:2% auto;
}
@media only screen and (max-width:1199px) {
     .login-section {
         width:98%;
    }
}
/*Pay Open Invoice*/
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc #form_filter_user_data {
         display:flex;
         margin-bottom:20px;
    }
     .po-invc #form_filter_user_data > * {
         margin:auto;
    }
     .po-invc .filter_order_search_label {
         line-height:12px;
    }
     .po-invc .my-account .pager p.amount strong, .po-invc .filter_order_search_label, .po-invc .my-account > p, .po-invc button.button span, .po-invc .pages strong, .po-invc .pages li, .po-invc .pages li a{
         font-size:12px !important;
         font-weight:400 !important;
    }
     .po-invc .pages ol {
         padding-left:0;
         margin-top:5px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
}

/*Blog*/
#blogcrumbs, .blog-sidebar, .article-block.blog-index .blog-post-wrapper .blog-page-content > p, .article-block.blog-index .blog-author, .blog .fa.fa-calendar, .blog .fa.fa-comments, .article-block.blog-index .blog-tag-data .list-inline.blog-tags, .article-block.blog-index .blog-tag-data-inner .fa-comments + a {
     display:none;
}
.blog-page {
     padding-bottom:0 !important;
}
.blog h1 {
     color:rgb(0, 61, 165);
     font-size:40px;
     text-transform:none;
     position:relative;
     padding:40px 15px;
     text-align:left;
     margin:0;
}
.blog h1#blog-title {
     text-indent:-9999px;
}
.blog h1#blog-title:after {
     text-indent:0;
     content:'dynarexcorp';
     float:left;
}
.blog .main {
     background-color:#b7d3e5;
}
.blog .main .col-main.grid-full {
     margin:0;
}
.blog .col-md-9.article-block {
     background-color:#fff;
     margin:0 auto;
     float:none;
}
.article-block.blog-index .blog-post-wrapper {
     display:inline-block;
     width:30%;
     margin:0 1.5% 50px;
     background:#fff;
     overflow:hidden;
     margin-bottom:50px!important;
     min-height:390px;
}
.article-block.blog-index .blog-post-wrapper h2 {
     margin-top:5px;
}
.article-block.blog-index .blog-post-wrapper h2 a {
     color:#1073AC!important;
     font-size:23px;
     line-height:28px;
}
.article-block.blog-index .blog-page-wrapper .blog-featured-image {
     width:100%!important;
     float:none!important;
}
.article-block.blog-index .blog-page-wrapper .blog-featured-image img {
     width:100%!important;
     float:none!important;
     border-radius:0;
}

.article-block.blog-index .blog-post-wrapper .blog-page-wrapper.row {
     position:relative;
}
.article-block.blog-index .blog-post-wrapper .blog-page-wrapper.row > .blog-page-content {
     position:absolute;
     bottom:0;
}
.article-block.blog-index .blog-tag-data-inner {
     background-color:#1073AC;
}
.article-block.blog-index .blog-tag-data-inner a {
     color:#fff;
}
.article-block.blog-index .blog-tag-data-inner .list-inline{
     margin:0;
     padding:5px 0;
}
#footer .blog-page-links a:hover {
     text-decoration:none !important;
}
/*Detail page*/
.blog-page.blog-detail .blog-tag-data .col-md-8, .blog-page.blog-detail .blog-tag-data .blog-tag-data-inner ul > li:nth-child(3), .blog-page.blog-detail .blog-post-wrapper > h3, .blog-page.blog-detail .blog-post-wrapper #comment_content {
     display:none;
}
.blog-page.blog-detail .blog-tag-data .blog-tag-data-inner ul > li, .blog-page.blog-detail .blog-tag-data .blog-tag-data-inner ul > li a {
     background-color:#1073AC;
     padding:7px;
     color:#fff;
}
.blog-page.blog-detail .blog-tag-data .blog-tag-data-inner {
     padding-left:20px;
}
@media only screen and (min-width:960px) {
     .blog-page.blog-detail .blog-post-wrapper {
         width:60%;
         margin:0 auto;
    }
}
.blog-page.blog-detail .blog-post-wrapper h1 {
     padding-left:0;
}
.blog-page.blog-detail .blog-page-content > * {
     margin-bottom:3%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .article-block.blog-index .blog-post-wrapper {
         width:46%;
    }
     .blog-page.blog-detail .row {
         margin-left:0;
    }
}
@media only screen and (max-width:767px) {
     .mobile-header {
         margin-bottom:0 !important;
    }
     .blog .col-main .std > .row {
         margin:0;
    }
     .article-block.blog-index .blog-post-wrapper {
         width:96%;
    }
}
.blog .pager .pages li a {
     background-color:#1073AC !important;
}
.blog .pager .pages li.current {
     background-color:#fff;
     color:#1073AC;
}
.blog-page-content ul li {
     list-style:disc;
}

